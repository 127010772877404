// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-how-to-order-tsx": () => import("./../../../src/pages/how-to-order.tsx" /* webpackChunkName: "component---src-pages-how-to-order-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-products-usa-made-tsx": () => import("./../../../src/pages/products/usa-made.tsx" /* webpackChunkName: "component---src-pages-products-usa-made-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-selling-ideas-tsx": () => import("./../../../src/pages/selling-ideas.tsx" /* webpackChunkName: "component---src-pages-selling-ideas-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-virtual-sample-tsx": () => import("./../../../src/pages/virtual-sample.tsx" /* webpackChunkName: "component---src-pages-virtual-sample-tsx" */),
  "component---src-templates-product-grid-tsx": () => import("./../../../src/templates/product-grid.tsx" /* webpackChunkName: "component---src-templates-product-grid-tsx" */),
  "component---src-templates-product-tsx": () => import("./../../../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

